import clsx from "clsx";
import { FC, memo, useMemo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { EntityStoreType } from "@/hooks/product/types";
import { useAppSelector } from "@/hooks/redux";
import { ShopType } from "@/hooks/shops/types";
import { cssTriggerDisabled, Trigger } from "@/styles/utils/Utils";
import { Modal } from "@/ui/Modal/Modal";
import { Span, Typography } from "@/ui/Typography/Typography";
import { declinationOfNum } from "@/utils/common/helpers";
import { STORES_DECLINATIONS } from "@/utils/constants";
import { StyledStoresAvailable } from "./StyledStoresAvailable";
import { StyledList, StyledListItem } from "../../../List/StyledList";
import { Store } from "../../../Shops/Store";
import { ExtendElementsType, ListMapPropsType } from "../../../Shops/types";
import { cssStoreAvailable } from "../../StyledProduct";
const ListMap = dynamic(((() => import("../../../Shops/ListMap").then(mod => mod.ListMap)) as DynamicOptions<ListMapPropsType>), {
  ssr: false
});
type StoresAvailablePropsType = {
  stores?: EntityStoreType[];
};
const shopsPickupMapFormatter = ({
  stores,
  shopsStore
}: {
  stores?: EntityStoreType[];
  shopsStore?: Record<string, ShopType> | null;
}) => {
  let arr: ShopType[] = [];
  const ext: ExtendElementsType = {};
  if (!!stores && !!shopsStore) {
    for (const store of stores) {
      const shopFullData = shopsStore[store.uuid];
      if (!shopFullData) {
        continue;
      }
      if (!shopFullData.pickup) {
        continue;
      }
      arr = [...arr, {
        ...shopFullData,
        address: store.name
      }];
      ext[store.uuid] = {
        element: () => <Store availableStatus={store.availableStatus} deliveryDate={store.deliveryDate} />
      };
    }
  }
  return {
    shopsFormatted: arr,
    shopsExtends: ext
  };
};
const StoresAvailableContent: FC<Required<StoresAvailablePropsType>> = memo(({
  stores,
  children
}) => {
  const storesQty = stores.length;
  const text = declinationOfNum(storesQty, STORES_DECLINATIONS);
  const {
    shops: shopsStore
  } = useAppSelector(({
    shop
  }) => shop);
  const {
    shopsPickup,
    shopsPickupExtends
  } = useMemo(() => {
    const {
      shopsFormatted,
      shopsExtends
    } = shopsPickupMapFormatter({
      shopsStore: shopsStore,
      stores
    });
    return {
      shopsPickup: shopsFormatted,
      shopsPickupExtends: Object.keys(shopsExtends).length > 0 ? shopsExtends : undefined
    };
  }, [shopsStore, stores]);
  return <StyledStoresAvailable>
        <StyledList>
          <StyledListItem>
            <Typography className={cssStoreAvailable}>
              <Span>В наличии: </Span>

              {shopsPickup.length <= 0 ? <Trigger className={clsx(cssTriggerDisabled)}>
                  на&nbsp;{storesQty}&nbsp;{text}
                </Trigger> : <Modal title={"Пункты самовывоза на карте"} closeMode={"destroy"} variant={"rounded-100"} disclosure={<Trigger>
                      на&nbsp;{storesQty}&nbsp;{text}
                    </Trigger>}>
                  <LazyHydrateContainer>
                    <ListMap shops={shopsPickup} extendElements={shopsPickupExtends} />
                  </LazyHydrateContainer>
                </Modal>}
            </Typography>
          </StyledListItem>
        </StyledList>
        {children}
      </StyledStoresAvailable>;
});
const StoresAvailable: FC<StoresAvailablePropsType> = ({
  stores,
  children
}) => {
  if (!stores || stores.length <= 0) {
    return null;
  }
  return <StoresAvailableContent stores={stores}>{children}</StoresAvailableContent>;
};
StoresAvailableContent.displayName = "StoresAvailableContent";
export type { StoresAvailablePropsType };
export { StoresAvailable };