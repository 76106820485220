import { FC, memo, useCallback } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { Button } from "@/ui/Button/Button";
import { cssButtonFastBuy } from "@/ui/Button/StyledButton";
import { ButtonSizeType } from "@/ui/Button/types";
import { Modal } from "@/ui/Modal/Modal";
import { GTAG_EVENTS } from "@/utils/constants";
import { gtagSend } from "@/utils/common/helpers";
const FastOrderForm = dynamic(((() => import("../../../Forms/FastOrder").then(mod => mod.FastOrderForm)) as DynamicOptions), {
  ssr: false
});
export const ButtonFastOrder: FC<{
  product?: {
    inCart: boolean;
    isRemoved: boolean;
    addToCart: () => void;
  };
  disabled?: boolean;
  size?: ButtonSizeType;
}> = memo(({
  product,
  disabled,
  size = "medium"
}) => {
  const onClickHandle = useCallback(() => {
    gtagSend({
      event: GTAG_EVENTS.ORDER_DONE
    });
    if (!product) {
      return;
    }
    if (!product.inCart || product.isRemoved) {
      product.addToCart();
    }
  }, [product]);
  return <Modal variant={"rounded-0"} title={"Быстрый заказ"} closeMode={"destroy"} isShowModal={false} disclosure={<Button variant={"outline"} size={size} className={cssButtonFastBuy} onClick={onClickHandle} disabled={disabled}>
          Быстрый заказ
        </Button>}>
      <FastOrderForm />
    </Modal>;
});
ButtonFastOrder.displayName = "ButtonFastOrder";